:root {
  color-scheme: dark;
  height: 100vh;
}

html{
  background: #303438;
}

body,h3,div,input,input[button], button{
  color: lightgray !important;
}

.colorBG{
  background-color: #303438;
}

h3{
  background-color: #0d6efd;
  border: none !important;
}
.bg-dark{
  /*background-image: url("/public/assets/images/background/background.png");*/
}
.App h3 {
  text-align: center;
}

img{
  border-radius: 11px;
  -moz-box-shadow: 0 0 2px #fff;
  -webkit-box-shadow: 0 0 2px #fff;
  box-shadow: 0px 0px 2px #fff;
  box-shadow: 0px 0px 2px #fff;
}

.imgNoRadius{
  border-radius: 0;
  -moz-box-shadow: 0 0 0px #fff;
  -webkit-box-shadow: 0 0 0px #fff;
  box-shadow: 0px 0px 0px #fff;
  box-shadow: 0px 0px 0px #fff;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  background-color: #303438;
}

.displayGrid{
  display: inline-grid;
}

.gridCol12{
  grid-column: 1/2;
}

.gridRow1{
  grid-row: 1;
}

.gridRow2{
  grid-row: 2;
}

.gridRow3{
  grid-row: 3;
}

.gridRow4{
  grid-row: 4;
}

.gridRow5{
  grid-row: 5;
}

.gridCol1{
  grid-column: 1;
}

.gridCol2{
  grid-column: 2;
}

.gridCol3{
  grid-column: 3;
}

.gridCol4{
  grid-column: 4;
}

.gridCol5{
  grid-column: 5;
}

.show{
  opacity: 1;
  display: block;
}

.hide{
  opacity: 0;
  display: none;
  transition: opacity 0.9s linear;
}

.displayBlock {
  display: block;
}

.wideMC {
  width: min-content;
}

.wide33 {
  width: 33%;
}

.mwide350 {
  max-width: 350px !important;
}

.mwide250 {
  max-width: 250px;
}

.mwide212{
  min-width: 212px;
}

.wide136px{
  width: 136px;
}
.wide211px{
  width: 211px !important;
}
.wide500px{
  width: 500px;
}

.wide100per{
  width: 100%;
}

.wide100perImp{
  width: 100% !important;
}

.wide80perImp{
  width: 80% !important;
}

.wide95per{
  width: 95%;
}

.wide75per{
  width: 75%;
}

.wide73per{
  width: 73%;
}

.wide72per{
  width: 72%;
}

.wide69vw{
  width: 69vw;
}

.wide50per{
  width: 50%;
}

.wide50perimp{
  width: 50% !important;
}

.wide30vw{
  width: 30vw;
}
.wide30vwimp{
  width: 30vw !important;
}

.wide15rem{
  width: 15rem;
}

.wide14rem{
  width: 14rem;
}

.wide12per{
  width: 12%;
}


.wide25per{
  width: 25%;
}
.wide2per{
  width: 2%;
}

.floatRight {
  float:right;
}

.floatLeft {
  float:Left;
}
.textLeft{
  text-align: left;
}
.wsPreWrap{
  white-space: pre-wrap;
}
.enlarge-onhover {
  width: 25%;
  height: 25%;
}

.borderBottomGray{
  border-bottom: solid 1px #888;
}

.hFC{
height: fit-content;
}

.h3Per{
height: 3%;
}

.h1100px{
height: 1100px;
}

.h92vh{
height: 92vh;
}

.h75vh{
height: 75vh;
}

.h65vh{
height: 65vh;
}

.h10vh{
height: 10vh;
}

.h190px{
  height: 190px;
}

.h130px{
  height: 130px;
}

.h295px{
height: 295px !important;
}

.h365px{
height: 365px;
}

#filterBtn input[type=checkbox]{
  display: none;
}

#filterBtn img{
  cursor: pointer;
}

#filterBtn label img{
  margin-left: .5vw;
  margin-right: .5vw;
}

/* CHECKED STYLES */
#filterBtn [type=checkbox]:checked label {
  outline: 2px solid #f00;
}

.overflwScroll{
 overflow: scroll;
 overflow-x: hidden;
 overflow-y: auto;
}

.enlarge-onhover:hover {
  width: 100%;
  height: 100%;
}

.deckCountStyle{
  width: 75px;
    text-align: center;
    font-weight: bold;
    color: black;
  }

  .fs17pt{
    font-size: 17pt;

  }

  .fs22pt{
    font-size: 22pt;

  }
  .posAbso{
  position: absolute;
}

.posRel{
  position: relative;
}

.posAbosolutez10 {
  position: absolute;
  z-index: 10;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #141619 !important;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  white-space: pre-wrap;
}

.colorBGBlack{
  background-color: black;
}


/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.cardDock{
  display: inline-grid;
    margin-left: 10px;
    margin-top: 10px;
}

.partialCard{
  object-fit: cover;
  object-position: 0px 0px;
  height: 4vw;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mt5px{
  margin-top:5px;
}

.pl5px{
  padding-left:5px;
}

.pl10px{
  padding-left:10px;
}

.pr3px{
  padding-right:3px;
}

.pt12px{
  padding-top:12px;
}

.pt27px{
  padding-top:27px;
}

.pt70px{
  padding-top:70px;
}

.pt90px{
  padding-top:90px;
}

.pt100px{
  padding-top:100px;
}

.pt120px{
  padding-top:120px;
}

.pt10px{
  padding-top:10px;
}

.pt5px{
  padding-top:5px;
}

.pb5px{
  padding-bottom:5px;
}
/* Tooltip container */
.tooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
.fontAwesome{
  font-family: "Font Awesome 5 Free";
  font-size: 1.3333333333333333em;
  font-weight: 900;
}
/* Tooltip text */
.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 16rem;
  padding: 7px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.checked {
  outline: #ffc107 solid 2px;
  border-radius: 40px 40px 0px 40px;
}

.removeBtn{
  border:none;
  background-color:transparent;
  outline:none;
}
[aria-modal="true"] img[data-internalid="1337"] {
  height: 4.2vw;
    width: 3vw !important;
    border-radius: 2px;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1550px) {
  .wide69vw{
    width: 67vw;
  }

}
.dialog {
  top: 9%;
  z-index: 11;
  position: absolute;
  height: 100%;
  background: none;
  border: none;  
  width: 100%;
  background-color: rgba(115,115,115,.5);  
}

.dialog img {
  box-shadow: 0 8px 6px -6px black;
  left: 25%;
  position: absolute;
  height: 69%;
}

.dialog dialog {
  position: absolute;
  width: 75%;
  height: 99%;
  overflow: hidden;
}

.cardDock .Ally {
  object-position: 0px -190px;
}
.cardDock .Crystal {
  object-position: 0px -194px;
}
.cardDock .Support {
  object-position: 0px -142px;
}
.cardDock .Stamina {
object-position: 0px -116px;
}